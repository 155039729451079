<template>
  <div class="www-wrapper">
    <div class="container">
      <h2 class="heading">
        Who we're working with
        <span class="trilon-pink heading-dot">.</span>
      </h2>

      <p class="subheading">
        We've worked with some of the largest enterprise companies and globally
        used open source projects. Now we're ready to help your project become a
        huge success.
      </p>

      <div class="row testimonials-wrapper justify-content-md-center">
        <div class="gradient-wrapper">
          <div class="testimonial">
            <div v-for="testimonial in testimonials" :key="testimonial.id">
              <div v-if="testimonial.id === currentTestimonial">
                <div class="author-wrapper">
                  <div class="avatar">
                    <img :src="testimonial.avatar" :alt="testimonial.author" />
                  </div>
                  <div class="author-info">
                    <h6 class="author-name">{{ testimonial.author }}</h6>
                    <h5 class="job-position">{{ testimonial.jobPosition }}</h5>
                  </div>
                </div>
                <p class="testimonial-text" v-html="testimonial.text"></p>
              </div>
            </div>
          </div>
        </div>
        <div class="selection-wrapper text-right">
          <arrow
            @click.native="previous($event)"
            class="arrow arrow-left"
          ></arrow>
          <div
            v-for="index in totalTestimonials"
            :key="index"
            @click="goToTestimonial(index - 1)"
            class="circle"
            v-bind:class="{ selected: index - 1 === currentTestimonial }"
          ></div>
          <arrow @click.native="next($event)" class="arrow"></arrow>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Arrow from '~/components/Arrow.vue';

export default {
  components: {
    Arrow,
  },
  computed: {
    totalTestimonials() {
      return this.testimonials.length;
    },
    testimonials() {
      return this.$store.testimonials;
    },
    currentTestimonial() {
      return this.$store.currentTestimonial;
    },
  },
  methods: {
    goToTestimonial: function (index) {
      if (index === this.currentTestimonial) {
        return;
      }
      this._changeTestimonial(index);
    },
    previous: function (event) {
      const index =
        this.currentTestimonial - 1 < 0
          ? this.totalTestimonials - 1
          : this.currentTestimonial - 1;

      this._changeTestimonial(index);
    },
    next: function (event) {
      const index =
        this.currentTestimonial + 1 > this.totalTestimonials - 1
          ? 0
          : this.currentTestimonial + 1;

      this._changeTestimonial(index);
    },
    // Private Methods
    _changeTestimonial: function (index) {
      this.$store.changeTestimonial(index);
    },
  },
};
</script>

<style lang="scss">
.www-wrapper {
  background: url('../assets/bg-bottom-wave.png') no-repeat bottom center;
  margin-top: 3rem;

  @media (min-width: 1440px) {
    background-size: contain;
  }

  .heading {
    padding-bottom: 1rem;
  }
}

.testimonials-wrapper {
  padding-top: 5rem;
  max-width: 1000px;
  margin: 0 auto;

  @media (max-width: 640px) {
    padding-left: 16px;
    padding-right: 16px;
  }  

  .avatar,
  .author-info {
    display: inline-block;
    vertical-align: middle;

    @media (max-width: 640px) {
      display: block;
    }
  }

  .avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;

    @media (max-width: 640px) {
      margin: 0 auto 1.2em;
    }
  }

  .author-info {
    text-align: left;
    padding-left: 1em;

    @media (max-width: 640px) {
      text-align: center;
      padding: 0;
    }
  }

  .author-name {
    margin-bottom: 4px;
  }

  .job-position {
    color: #e91e63;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  }

  .testimonial-text {
    margin: 2.5em 0 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;

    strong {
      color: #e91e63;
    }

    @media (max-width: 480px) {
      font-size: 15px;
    }
  }

  .gradient-wrapper {
    padding: 5px;
    > div {
      padding: 80px 110px;
      text-align: center;

      @media (max-width: 1000px) {
        padding: 80px 60px;
      }

      @media (max-width: 640px) {
        padding: 48px 24px;
      }  
    }
  }
}

.selection-wrapper {
  margin: 3rem auto 0;

  * {
    vertical-align: middle;
    cursor: pointer;
  }
  .fas {
    position: relative;
    top: -3px;
    color: #b1b1b1;

    &.fa-arrow-left {
      margin-right: 2rem;
    }
    &.fa-arrow-right {
      margin-left: 2rem;
    }
  }

  .circle {
    background: #302641;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: inline-block;
    margin: 0 0.25rem;

    &:hover {
      background: #ccc;
    }

    &.selected {
      cursor: initial;
      background: #e91e63;
      &:hover {
        background: #e91e63;
      }
    }
  }
}

.arrow:hover path {
  fill: #e91e63;
}

.arrow-down {
  transform: rotate(90deg);
}

.arrow-left {
  transform: rotate(-180deg);
  margin-right: 1rem;
  margin-left: 0;
}
</style>
